import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  open() {
    this.navbar.setAttribute("open", true)

    this.navbar.classList.remove("mix-blend-difference")
  }

  close() {
    this.navbar.removeAttribute("open")

    setTimeout(() => {
      this.navbar.classList.add("mix-blend-difference")
    }, 350)
  }

  toggle() {
    this.isOpened ? this.close() : this.open()
  }

  // Private

  get navbar() {
    return this.hasElementTarget ? this.elementTarget : this.element
  }

  get isOpened() {
    if (this.navbar.hasAttribute("open")) return true
    else return false
  }
}
