import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    scheme: String
  }

  connect() {
    document.documentElement.dataset.colorScheme = this.schemeValue
  }
}
