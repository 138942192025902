
        import * as module0 from './controllers/cover_controller.js';import * as module1 from './controllers/details_controller.js';import * as module2 from './controllers/form_controller.js';import * as module3 from './controllers/logo_extender_controller.js';import * as module4 from './controllers/map_controller.js';import * as module5 from './controllers/nav_mobile_controller.js';import * as module6 from './controllers/switch_color_controller.js';import * as module7 from './controllers/switch_locale_controller.js';import * as module8 from './controllers/video_controller.js'
        const modules = {
            "./controllers/cover_controller.js": module0,
            "./controllers/details_controller.js": module1,
            "./controllers/form_controller.js": module2,
            "./controllers/logo_extender_controller.js": module3,
            "./controllers/map_controller.js": module4,
            "./controllers/nav_mobile_controller.js": module5,
            "./controllers/switch_color_controller.js": module6,
            "./controllers/switch_locale_controller.js": module7,
            "./controllers/video_controller.js": module8,
        };
        export default modules;
      