import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  static values = {
    autoScroll: Boolean
  }

  connect() {
    this.observer = new IntersectionObserver((elements) => {
      const cover = elements[0]

      if (cover.isIntersecting) {
        this.dispatch("visible")
      } else {
        this.dispatch("hidden")
      }
    }, { rootMargin: "-1px", threshold: 0 })

    this.observer.observe(this.cover)
  }

  // Private

  get cover() {
    return this.hasElementTarget ? this.elementTarget : this.element
  }
}
