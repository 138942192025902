import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  play() {
    if (!this.hasVideo) return

    if (!this.video.hasAttribute("src") && this.video.hasAttribute("data-src")) {
      this.video.src = this.video.dataset.src
    }

    this.video?.play()
  }

  pause() {
    if (!this.hasVideo) return

    this.video?.pause()
  }

  get video() {
    return this.hasElementTarget ? this.elementTarget : this.element
  }

  get hasVideo() {
    return this.video?.tagName == "VIDEO"
  }
}
