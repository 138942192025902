import { Controller } from "@hotwired/stimulus"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import quarries from "../quarries.json"

export default class extends Controller {
  static values = {
    coordinates: Object,
    mapboxStyle: String
  }

  connect() {
    mapboxgl.accessToken = "pk.eyJ1IjoidG9tbWFzb25nciIsImEiOiJjazdjM2ZuMXowMG9zM2dxam10eW5qc3BsIn0._qrzztL_YLNvgnFdv89Xew"

    const coordinates = new mapboxgl.LngLat(this.coordinatesValue.longitude, this.coordinatesValue.latitude)
    let firstRun = true

    this.map = new mapboxgl.Map({
      container: this.element,
      style: this.mapboxStyleValue,
      projection: "globe",
      attributionControl: false,

      center: [80, 36],
      zoom: 1,
      pitch: 0,

      maxPitch: 70,

      scrollZoom: false,
      boxZoom: false,
      dragPan: false,
      dragRotate: false,
      touchZoomRotate: false,
      touchPitch: false,
      doubleClickZoom: false,
    })

    this.map.once("moveend", () => {
      this.map.dragRotate.enable()
      this.map.touchPitch.enable()
      this.map.setMinPitch(40)
    })

    this.map.on("load", () => {
      this.map.addSource("quarries", {
        "type": "geojson",
        "data": quarries
      })

      this.map.addLayer({
        "id": "quarry-fill-extrusions",
        "type": "fill-extrusion",
        "source": "quarries",
        "minzoom": 14,
        "paint": {
          "fill-extrusion-color": "#ffffff",
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            0,
            15,
            ["get", "height"]
          ],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.7,
          "fill-extrusion-vertical-gradient": false
        }
      })
    })

    this.resizeObserver = new ResizeObserver(() => {
      if (firstRun) return

      this.map.flyTo({
        center: coordinates,
        zoom: 15,
        bearing: 140,
        pitch: 70,
        duration: 2000,
        easing: function(x) {
          return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
        },
        essential: true
      })

      this.map?.resize()
    })
    this.resizeObserver.observe(this.element)

    this.intersectionObserver = new IntersectionObserver((el) => {
      if (!firstRun || !el[0].isIntersecting) return

      this.map.flyTo({
        center: coordinates,
        zoom: 15,
        bearing: 140,
        pitch: 70,
        duration: 15000,
        easing: function(x) {
          return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
        },
        essential: true
      })

      firstRun = false
    }, {
      rootMargin: "0px",
      threshold: 0.5
    })
    this.intersectionObserver.observe(this.element)
  }

  disconnect() {
    this.resizeObserver.disconnect()
    this.intersectionObserver.disconnect()
  }
}
