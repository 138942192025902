import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["logotype"]
  static classes = ["hidden"]
  static values = {
    threshold: Number
  }

  toggle() {
    let threshold = this.thresholdValue != -1 ? this.thresholdValue : window.innerHeight

    if (window.scrollY > threshold) {
      this.logotypeTarget.classList.remove(this.hiddenClass)
    } else {
      this.logotypeTarget.classList.add(this.hiddenClass)
    }
  }
}
