import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  reset(e) {
    e.preventDefault()
    e.stopPropagation()

    e.target.reset()
  }
}
